import { render, staticRenderFns } from "./default.vue?vue&type=template&id=598f2962&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormTopLanguageSwitch: require('/codebuild/output/src3562520020/src/med-el-hearpeers/components/Form/TopLanguageSwitch.vue').default,LayoutSkipLinks: require('/codebuild/output/src3562520020/src/med-el-hearpeers/components/Layout/SkipLinks.vue').default,LayoutHeader: require('/codebuild/output/src3562520020/src/med-el-hearpeers/components/Layout/Header.vue').default,LayoutDrawer: require('/codebuild/output/src3562520020/src/med-el-hearpeers/components/Layout/Drawer/index.vue').default,LayoutFooter: require('/codebuild/output/src3562520020/src/med-el-hearpeers/components/Layout/Footer.vue').default,LayoutDialogs: require('/codebuild/output/src3562520020/src/med-el-hearpeers/components/Layout/Dialogs.vue').default,LayoutLayer: require('/codebuild/output/src3562520020/src/med-el-hearpeers/components/Layout/Layer.vue').default,FormMicrosoftDynamics: require('/codebuild/output/src3562520020/src/med-el-hearpeers/components/Form/MicrosoftDynamics.vue').default})
